// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./success.scss"></require>
  <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="success" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
    </div>
    <div class="flex-grow"></div>
    <div class="checkmark_text flex-column">
      <animated-checkmark></animated-checkmark>
      <h5 class="congratulation">Your order is on the way!</h5>
    </div>
    <div class="flex-grow"></div>
    <div class="info flex-column">
      <div class="address flex-column">
        <h5 class="title">Delivery Address:</h5>
        <p>616 Avenue de la Libération 62700 <br> Bruay-la-Buissière</p>
      </div>
      <div class="tracking flex-column">
        <h5 class="title">Tracking ID:</h5>
        <p>HRD50573011</p>
      </div>
    </div>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;