// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/andromeda.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/checkfill.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./redeem.scss"></require>
  <section id="redeem" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
    </div>
    <img class="nft" src="${___HTML_LOADER_IMPORT_1___}">
    <div class="flex-column info">
      <div class="author_like flex-row">
        <div class="author_check flex-row">
          <a class="author" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Creator</a>
          <img class="check" src="${___HTML_LOADER_IMPORT_2___}">
        </div>
        <div class="flex-grow"></div>
      </div>
      <p class="nft_name">This is a test enjoy</p>
      <p class="nft_number gray">#1</p>
    </div>
    <div class="spacer-default"></div>
    <div class="price_container flex-column">
      <h3 class="title gray">Current price</h3>
      <div class="bsv_eur flex-row">
        <h2 class="bsv_price">₿ 0.38095</h2>
        <div class="flex-grow"></div>
        <p class="eur_price gray">€18.25</p>
      </div>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary">
      <span>Redeem</span>
    </button>
    <button class="btn btn-secondary">
      <span>Send</span>
    </button>
  </section>
</template>

`;
// Exports
export default code;